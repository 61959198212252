/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: tahoma;
  font-style: normal;
  font-weight: normal;
  src: url('./tahoma/tahoma.eot'); /* IE9 Compat Modes */
  src: url('./tahoma/tahoma.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./tahoma/tahoma.otf') format('opentype'),
    /* Open Type Font */ url('./tahoma/tahoma.svg') format('svg'),
    /* Legacy iOS */ url('./tahoma/tahoma.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./tahoma/tahoma.woff') format('woff'),
    /* Modern Browsers */ url('./tahoma/tahoma.woff2') format('woff2'); /* Modern Browsers */
}

@font-face {
  font-family: tahomabd;
  src: url('./tahomaBold/tahomabd.eot'); /* IE9 Compat Modes */
  src: url('./tahomaBold/tahomabd.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./tahomaBold/tahomabd.otf') format('opentype'),
    /* Open Type Font */ url('./tahomaBold/tahomabd.svg') format('svg'),
    /* Legacy iOS */ url('./tahomaBold/tahomabd.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./tahomaBold/tahomabd.woff') format('woff'),
    /* Modern Browsers */ url('./tahomaBold/tahomabd.woff2') format('woff2'); /* Modern Browsers */

  font-weight: normal;
  font-style: normal;
}

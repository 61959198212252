@import 'src/resources/css/theme/KtCommonColors';

td,
th {
  vertical-align: top;
  padding: 0.5rem 0;
  width: 8rem;
  text-align: center;
  border-right: 2px solid rgb(34 36 38 / 10%); 
  &.cursorPointer{
    cursor: pointer;
  }
}

.timesheet_filters {
  justify-content: flex-end;
  display: flex;
  margin-bottom: 1rem;

  .filter-display {
    display: flex;
    align-items: center;
    margin-left: 1rem;

    .kt-m-checkbox {
      .checkbox-label {
        padding: 0 0.2rem;
      }
    }

    .filter-name {
      font-size: 1.2rem;
    }
  }
}

.timeSheet-table {
  position: relative;
  font-family: tahoma, sans-serif;

  &__inner {
    overflow-x: auto;
    margin-left: 300px;

    &__main {
      table-layout: fixed;
      width: fit-content;
      *margin-left: -100px; /* ie7 */
      &__header {
        background-color: $gray-border-color;
        font-size: 1.1rem;
        font-weight: 600;
        color: $kt-color-primary-blue;
        border-bottom: 2px solid rgb(34 36 38 / 10%);

        .header-1 {
          position: absolute;
          *position: relative; /* ie7 */
          left: 0;
          background-color: $gray-border-color;
          width: 200px;
          text-align: left;
          padding-left: 1rem;
          border-right: 2px solid rgb(34 36 38 / 10%);
          border-bottom: 2px solid rgb(34 36 38 / 10%);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 1.2rem;
        }

        .header-2 {
          position: absolute;
          *position: relative; /* ie7 */
          left: 200px;
          width: 100px;
          background-color: $gray-border-color;
          text-align: left;
          padding-left: 1rem;
          border-right: 3px solid rgb(34 36 38 / 10%);
          border-bottom: 2px solid rgb(34 36 38 / 10%);
          font-size: 1.2rem;
        }
      }

      &__body {
        font-size: 1.1rem;
        color: $kt-heading-color;
        border-bottom: 2px solid $gray-border-color;
        background-color: $white;

        .header-1 {
          position: absolute;
          *position: relative; /* ie7 */
          left: 0;
          width: 200px;
          text-align: left;
          padding-left: 1rem;
          border-bottom: 2px solid $gray-border-color;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          background-color: $white;
        }

        .header-2 {
          position: absolute;
          *position: relative; /* ie7 */
          left: 200px;
          width: 100px;
          text-align: left;
          padding-left: 1rem;
          border-right: 3px solid rgb(34 36 38 / 10%);
          border-bottom: 2px solid $gray-border-color;
          background-color: $white;
        }

        .hideColumn {
          visibility: hidden;
        }

        .grey {
          background-color: $kt-color-blank;
          height: 3rem;
        }

        .Missing {
          color: $kt-heading-color;
          background-color: $kt-color-light-red;
        }

        .Late {
          color: $kt-heading-color;
          background-color: $kt-color-light-yellow;
        }

        .highlighted {
          color: $kt-brick-color;
          background-color: $kt-color-highlight-yellow;
        }

        .tooltip-inner {
          padding-right: 1rem;

          .inner-row {
            display: flex;
            align-items: center;
            color: $kt-color-secondary-blue;
            font-size: 1rem;

            &_heading {
              text-align: right;
              width: 100px;
            }

            &_val {
              font-weight: 600;
              padding-left: 0.5rem;
            }
          }
        }
      }
    }
  }
}

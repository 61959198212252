@import 'src/resources/css/theme/KtCommonColors';

.tooltip-popup {
  font-family: Tahoma, sans-serif;

  .modal-header {
    align-items: center;

    .popup-heading {
      flex: 2;
      color: $kt-color-primary-blue;
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0 0.6 rem 1 rem 0;
    }

    .edit-icon-container {
      float: right;

      .edit-icon {
        height: 1.1rem;
        width: 1.1rem;
        cursor: pointer;
      }
    }
  }
}

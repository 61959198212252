@import 'src/resources/css/theme/KtCommonColors';

.kt-search-rectangle {
  background-color: $white;
  height: 4.8rem;
  width: 82.2rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  border: 0.1rem solid rgb(0 0 0 / 10%);
  font-family: tahoma, sans-serif;
  position: relative;

  .lds-ellipsis {
    top: 0 !important;
    margin-right: 2rem;

    div {
      background: #114583;
    }
  }

  .kt-search-container {
    width: 95%;
    padding: 1.5rem 2.4rem;

    .kt-search-input {
      color: rgb(12 40 72 / 90%);
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.8rem;
      border: none;
      width: 100%;

      &:focus,
      &:hover {
        outline: none;
      }
    }

    .kt-img-icon {
      width: 1.5rem;
      height: 1.5rem;
      display: inline-block;
      cursor: pointer;
      background-repeat: no-repeat;
    }

    ::placeholder {
      color: rgb(12 40 72 / 90%) !important;
      opacity: 1;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.8rem;

      /* Firefox */
    }
  }

  &.admin-search {
    height: 3rem;
    width: 25rem;
    border: 1px solid $kt-color-primary-blue;

    .kt-search-container {
      padding: 0.5rem;

      ::placeholder {
        color: rgb(12 40 72 / 90%) !important;
        opacity: 1;
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1.8rem;
      }
    }

    .kt-img-icon {
      width: 1.3rem;
      height: 1.3rem;
      display: inline-block;
      cursor: pointer;
      background-repeat: no-repeat;
      margin-right: 0.5rem;
    }
  }
}

.kt-search-main-container {
  padding: 0 2.4rem 0 2rem;
}

.kt-searched-result-container {
  max-height: 23rem;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: none;
  position: absolute;
  top: 4.8rem;
  left: 0;
  width: 100%;
  box-shadow: 0 0.1rem 0.5rem 0 rgb(0 0 0 / 10%);
  background: #fff;
  margin-left: 0.1rem;
  z-index: 1;
  font-family: tahoma, sans-serif;

  .pipe {
    margin: 0 0.3rem;
  }

  .kt-member-image {
    margin-left: 0.3rem;

    .kt-view-icon {
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  .kt-searched-result-list {
    .kt-style-line {
      height: 0.1rem;
      width: 77.4rem;
      transform: scaleY(-1);
      border: 0.1rem solid rgb(0 0 0 / 5%);
    }

    .kt-searched-item {
      cursor: pointer;
      height: 7rem;
      display: flex;
      align-items: center;

      .member-left-container {
        display: flex;
        flex: 1 1;
        align-items: center;

        .kt-user-image {
          height: 4.8rem;
          width: 4.8rem;
          display: flex;
          align-items: center;
        }

        .kt-member-name {
          height: 1.8rem;
          color: rgb(12 40 72 / 90%);
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 1.8rem;
        }

        .kt-member-role {
          display: flex;
          height: 1.5rem;
          color: rgb(52 85 124 / 70%);
          font-size: 1rem;
          align-items: center;

          .kt-member-practiceArea {
            font-weight: 600;
          }

          .kt-member-topicSector {
            padding-left: 0.3rem;
          }
        }
      }

      .member-right-container {
        display: flex;
        flex: 1;
        font-size: 1rem;
        text-align: center;
        height: 2.3rem;
        justify-content: flex-end;

        .kt-member-designation {
          display: flex;
          align-items: center;
          border: 0.1rem solid rgb(0 0 0 / 10%);
          border-radius: 0.4rem;
          padding: 0 0.6rem;
          color: rgb(12 40 72 / 70%);
        }

        .kt-member-role-count {
          width: 2.8rem;
          margin-left: 1.8rem;
          border: 0.1rem solid rgb(0 0 0 / 10%);
          border-radius: 0.4rem;
          line-height: 2.3rem;
          color: rgb(12 40 72 / 70%);
        }
      }

      &.not-clickable {
        cursor: default;
      }

      &:last-child {
        border-radius: 0 0 1.2rem 1.2rem;
      }
    }
  }
}

@import 'src/resources/css/theme/KtCommonColors';

.toggle-filters{
  color: $white;
  cursor: pointer;

  &.floatRight{
    float: right;
    display: flex;
    margin-top:1.5rem;
  }
  &.floatLeft{
    padding: 0;
    margin-top:9.5rem;
    position: fixed;
    rotate: 90deg;
    left:-2rem;
  }
  .arrow {
    &.left{
      border-right:13px solid $kt-color-secondary-blue; 
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
    }
    &.top{
      border-left: 40px solid transparent;
      border-right: 40px solid transparent;
      border-bottom: 13px solid $kt-color-secondary-blue;
    }
    width: 0; 
    height: 0;  
  }
  .content{
    background-color: $kt-color-secondary-blue;
    padding: .5rem 1rem;
  }
}

.Kt_dashboard_body_right {
  min-height: 93.7vh;
  background-color: $gray-top-light;
  margin-top: 3rem;
  .filters_container{
    padding:1.5rem;
    .heading{ 
      display: flex;
      font-weight: 600;
      .left{
        flex:1;
        font-size: 1.4rem;
        color: $kt-color-secondary-blue;
      }
      .right{
        font-size: 1.2rem;
        cursor: pointer;
        color:#0774C1;
        &:hover{
          text-decoration: underline;
        }
    }
  }
  .filters_list  {
      list-style: none;
      padding: 0;
      margin-top: 1.5rem;
      li {
        font-size: 1.2rem;
        font-weight: 400;
        cursor: pointer;
        color: $kt-color-secondary-blue;
        border-bottom: 2px solid $kt-color-light-gray-2;
        padding: 1.5rem 0;
        .filter-heading {
          display: flex;
          .filter-name {
            display: flex;
            flex: 1;
            padding-bottom: 0.5rem;
            .text{
              flex:1;
              display: flex;
              align-items: center;
              .selected-count {
                border-radius: 50%;
                border: 1px solid $kt-color-secondary-blue;
                padding: 0 0.3rem;
                font-size: 1rem;
                height: fit-content;
                font-weight: 500;
                margin-left: 1rem;
                background-color: $white;
              }
            }
          }

        }
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
        &:first-child {
          padding-top: 0;
        }
        .submenu-text {
          flex: 1;
          text-align: left;
        }
      }
    }
    .dropdownWith-btn {
      position: relative;
      font-size: 1.2rem;
      color: $kt-color-secondary-blue;
      font-weight: 500;
      .dropdown-selected-option {
        display: flex;
        align-items: center;
        cursor: pointer;
        span{
          flex:1;
        }
        .iconContainer {
          margin-left: 0;
          margin-top: -0.5rem;
          i {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

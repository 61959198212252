@import 'src/resources/css/theme/KtCommonColors';
.infoText {
  font-size: 1.5rem;
  font-family: Tahoma, sans-serif;
  padding: 1rem;
}
.Kt_dashboard {
  width: 100%;
  height: auto;
  font-family: Tahoma, sans-serif;
  background-color: #f0f3f5;
  .ui.grid {
    margin: 0;

    > .row {
      padding: 0;
      margin: 0;

      > .column {
        padding: 1rem 0;
      }
    }
  }

  &_body {
    .pageLoader{
      margin-top: 12.5rem;
      width: 100%;
      display: flex;
      justify-content: center;
      p{
        color: $kt-color-secondary-blue;
        font-size: 1.5rem;
        margin-left: 2rem;
      }
    }
    .hide {
      display: none !important;
    }

    display: flex;

    &.ui.grid > .column:not(.row) {
      padding:0;
      &.Kt_dashboard_body_left{
        width: 2.5% !important;
      }
      &.Kt_dashboard_body_middle{
        width: 85% !important;
        padding-left: 2rem;
        padding-right: 2rem;
        &.analyst_container{
          width: 97.5% !important;
        }
        &.admin_main_container{
          width: 97.5% !important;
          padding-right: 0;
        }
      }
    }

    &_middle {
      width: 100% !important;
      margin-top: 3rem;
      &.marginLeft10{
        margin-left: 10rem;
      }
      .ui.grid > .column:not(.row) {
        padding: 0;
      }

      .Kt_main_body {

        &_heading {
          &_left {
            color: $kt-color-primary-blue;
            font-size: 2rem;
            font-weight: 600;
            .heading_value {
              display: flex;
              align-items: center;

              .tooltip-with-icon.homeTileToolTip {
                flex: 1;
                left: 1rem;
                position: relative;
                justify-content: flex-start;
                bottom: 0;
              }

              .tooltip-with-icon > svg {
                height: 2.3rem;
              }
            }
          }

          &_right {
            display: flex !important;
            align-items: center;
            justify-content: flex-end;
            .download-button {
              float: right;
              width: fit-content;
              display: flex;
              align-items: center;
              background-color: #113865;
              color: $white;
              padding: 0.5rem 1rem;
              border-radius: 5px;
              font-size: 1.2rem;
              font-weight: 400;
              cursor: pointer;

              img {
                width: 1.3rem;
                height: 1.2rem;
                margin-right: 0.5rem;
              }
            }
          }
        }

        &_tabs {
          display: flex;

        }
      }
    }
  }
}


.kt-table-container {
  min-height: 80vh;
  padding: 1.5rem 0;
  position: relative;
  width: 100%;
  margin: 0;
  height: auto;
  overflow: hidden;

  &__chart {
    min-height: 72vh;

    &__heading {
      font-size: 2rem;
      font-weight: 600;
      color: $kt-color-primary-blue;
      margin-bottom: 1.5rem;
    }

    &__headers {
      display: flex;

      &__sortby {
        flex: 1;
        display: flex;
        color: $kt-color-primary-blue;
        font-size: 1.2rem;
        font-weight: 600;
        align-items: center;

        .buttonContainer {
          padding-top: 0;
          width: fit-content;
          margin-bottom: 1rem;
          .ui.button.primary{
            width: 100%;
            background: #e7f6ff;
            color: $kt-color-primary-blue;
            letter-spacing: 0;
            font-size: 1.1rem;
            font-weight: 600;
            padding:.5rem;
            border: 1px solid $kt-color-primary-blue;
            &:active{
              background-color: #e7f6ff !important;
            }
            &:hover {
              transform: none;
            }
          }

          &.show {
            box-shadow: none;
            border-color: none;
          }

          .dropdown-heading {
            display: none;
          }

          .dropdown {
            .dropdown-toggle {
              font-size: 1.2rem;
              padding: 0.2rem 0 0.3rem;
              margin-left: 0.3rem;

              :focus,
              :active {
                border: 1px solid $kt-color-primary-blue;
              }

              &.show {
                padding: 0 1.2rem;
              }

              &::after {
                width: 1.5rem;
                height: 1.5rem;
                margin-left: 1rem;
              }
            }

            .dropdown-menu {
              &.show {
                margin-top: 2rem;
                width: 100%;

                .dropdown-item {
                  color: $kt-color-primary-blue;
                  font-size: 1.2rem;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }

      &__legends {
        display: flex;
        flex: 2;
        justify-content: flex-end;

        &__item {
          margin-right: 1.5rem;
          font-size: 1rem;
          font-weight: 500;

          &__mark {
            margin-right: 0.5rem;
            width: 1rem;
            line-height: 1rem;
            height: 1rem;
            border-radius: 50%;
          }
        }
      }
    }

    .ui.sortable.table {
      border: none;
      #tooltip {
        right: 0;
      }
      thead {
        background-color: $gray-border-color;

        th {
          font-size: 1.2rem;
          font-family: Tahoma, sans-serif;
          font-weight: 600;
          color: $kt-color-primary-blue;
          padding: 0.5rem 1rem;
          position: relative;
          cursor: default;
          .heading-content {
            display: flex;
            padding-right: 1rem;

            .heading {
              flex: 1;
              margin-right: 1rem;
            }
            .filter-icon{
              margin:-1rem -1rem 0 0;
              cursor: pointer;
              &__dot{
                height: 0.4rem;
                margin: 0;
                font-family: Tahoma, sans-serif;
                font-size: 1.5rem;
                font-weight: bolder;
              }
            }
            .desc {
              top: 2.2rem;
              position: absolute;
              height: 0;
              right: 1rem;
            }
            .acc {
              position: absolute;
              height: 0;
              right: 1rem;
            }
          }
        }
        .headerSortDown,
        .headerSortUp {
          height: 0;
          font-size: 0;
        }

        .headerSortDown:after,
        .headerSortUp:after {
          cursor: pointer;
          content: ' ';
          position: relative;
          border: 7px solid transparent;
          font-size: 0;
        }

        .headerSortDown:after {
          border-bottom-color: #979797;
        }

        .headerSortUp:after {
          border-top-color: #979797;
        }

        .headerSortDown:hover::after {
          border-bottom-color: #113865;
        }

        .headerSortUp:hover::after {
          border-top-color: #113865;
        }

        .blueBgDown:after {
          border-bottom-color: #113865;
        }

        .blueBgUp:after {
          border-top-color: #113865;
        }
      }

      .tooltip-inner{
        padding:1rem !important;
        border:1px solid #21bf61;
      }

      tbody tr {
        font-size: 1.2rem;
        font-family: "tahoma", sans-serif;
        font-weight: 500;
        color: $kt-heading-color;
        border-bottom: 2.5px solid $gray-border-color;
        background-color: $white;
        &.grey{
          background-color: $gray-top-light;
          color: $kt-color-primary-blue;
        }
        td {
          vertical-align: middle;
          padding: 0 0.5rem;
        }

        .employee_name {
          vertical-align: top;
          border: none;
          border-bottom: 2px solid $gray-border-color;
          width: 15%;
          word-break: keep-all;
          padding: 0 1rem;
        }

        .name {
          border: none;
          border-bottom: 2px solid $gray-border-color;
          white-space: nowrap;
          max-width: 30rem;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0.5rem 1rem;
        }

        .digit_val {
          border: none;
          border-bottom: 2px solid $gray-border-color;
          padding: 0.2rem 1rem;
        }

        .name_columns {
          border: none;
          border-bottom: 2px solid $gray-border-color;
          padding:0.5rem 1rem;
          &.team_member_name {
            width: 35%;
          }
          &.job_title {
            width: 25%;
          }
          &.home_office {
            width: 20%;
          }
          &.digit_columns {
            width: 10%;
          }
        }

        #bar_chart_cell {
          .highcharts-container {
            width: 100% !important;

            > svg {
              width: 100% !important;
            }
          }
        }

        .bar_chart_cell {
          border: none;
          border-bottom: 2px solid $gray-border-color;
          width: 50%;
        }

        .bar_chart_member {
          display: flex;
          border: none;
          padding: 0.5rem;
          width: 100%;
          align-items: center;
          &_left{
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
          &_right{
            width: 90%;
            display: flex;
            align-items: center;
          }
          .totalPercentage{
            margin-left: 0.5rem;
            font-weight: 600;
            font-size: 1.1rem;
          }
          .bar_graph {
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            &.caseBillability,&.clientWithout,&.innovationFund {
              color: $white;
            }
      
            &.total {
              width: 10%;
            }
          }
        }
      
      }
    }
  }
}
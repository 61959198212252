@import 'src/resources/css/theme/KtCommonColors';

.edit-container {
  padding: 1.1rem;
  width: 100%;
  font-size: 1.4rem;

  .startText {
    color: $kt-color-dark-blue;
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;

    .admin-help {
      text-decoration: underline;
      color: $kt-color-dark-blue;
      margin-left: 0.5rem;
    }
  }

  .dropdown-container {
    display: flex;
    align-items: center;

    .edit-dropdown {
      border: 1px solid $kt-color-primary-blue;
      border-radius: 1rem;
      color: $kt-color-primary-blue;
      font-size: 1rem;
      cursor: pointer;
      display: inline-block;
      width: 26rem;

      &.role-dropdown {
        width: 22rem;
        margin-right: 1rem;
      }

      .kt-drop-down-container {
        width: 100%;
        padding-top: 0;

        &.show {
          box-shadow: none;
          background-color: transparent;
          border: none;
        }

        .dropdown-heading {
          display: none;
        }

        .dropdown {
          padding-top: 0;

          .dropdown-toggle {
            padding-top: 1rem;
            font-size: 1.1rem;
          }

          .dropdown-toggle::after {
            float: right;
            width: 2rem;
            height: 2rem;
          }
        }

        .dropdown-menu.show {
          width: 100%;
          border-top: none;
          border-radius: 0.5rem;
          margin-top: 0.1rem;
        }
      }
    }
  }

  .editor {
    .information-editor {
      width: 100%;

      .DraftEditor-editorContainer {
        z-index: 0;
      }
    }
  }

  .button-container {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .button {
      padding: 0.5rem 2.55rem;
      font-size: 1.2rem;
      font-weight: 600;
      border-radius: 0.5rem;
      margin-right: 2rem;
      cursor: pointer;

      &.save-button,
      &.update-button {
        background-color: $kt-color-primary-blue;
        color: $white;
      }

      &.delete-button {
        background-color: $kt-color-red;
        color: $white;
      }

      &.cancel-button {
        border: 1px solid $kt-color-primary-blue;
      }
    }
  }
}

@import 'src/resources/css/theme/KtCommonColors';

.global-tabs {
  margin: 0 auto;

  ul {
    padding-left: 0;
    list-style: none;
    margin: 0;
    border-bottom: 3px solid $gray-border-color;
  }

  a.tab-link.custom-link {
    color: $kt-color-secondary-blue;
    line-height: 1rem;
    font-size: 1.3rem;
    padding: 1rem 0;
    display: inline-block;
    text-decoration: none;
  }

  li.tab {
    display: inline-block;
    padding: 0;
    margin-right: 3rem;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid transparent;
      border-bottom: 4px solid $kt-color-secondary-blue;
    }
    &.active {
      border-bottom: 4px solid $kt-color-secondary-blue;
      a.tab-link.custom-link {
        font-weight: bold;
      }
    }
  }
}

.sub-global-tab {
  a.tab-link.custom-link {
    color: $kt-color-primary-dark-blue;
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  a.tab-link.custom-link.active {
    color: $kt-color-active-tab;
    border-bottom: 2px solid $kt-color-primary-dark-blue;
  }
}

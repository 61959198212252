html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  font-family: Inter, sans-serif;
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 56%;
  }
}

@media only screen and (min-width: 1918px) and (max-width: 1999px) {
  html {
    font-size: 75%;
  }
}

@media only screen and (min-width: 2000px) {
  html {
    font-size: 100%;
  }
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Inter, sans-serif;
}

// unsetting default margin
p {
  margin: 0;
}

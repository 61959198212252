@use 'sass:color';
@import 'src/resources/css/theme/KtCommonColors';
@import 'src/resources/css/typography';
.dateRangeContainer {
  position: relative;
  margin-top: 1rem;
  &.marginBottom10{
    margin-bottom: 1rem;
  }
  
  &.fromDateCalender{
    flex:1;
  }
  &.toDateCalender{
    flex:1;
  }
  
  &:focus {
    outline-width: 0;
  }

  .title {
    color: $kt-heading-color;
    position: relative;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .calendarContainer {
    height: auto;
    width: fit-content;
    overflow: hidden;
    background-color: $white;
    padding:.5rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: absolute;
    right: 0;

    .react-calendar {
      width: 100%;
      font-family: Tahoma, sans-serif;
      border: none;
      padding: 0;
      cursor: pointer;
      .current {
        border-bottom: 1px solid $kt-color-primary-blue;
        margin-left: 0.2rem;
        padding: 1.1em 1.42rem;
        position: absolute;
        top: 0;
        left: 0;
      }
      &__tile {
        position: relative;
        padding: 0.25rem 0;
        font-size: 1.1rem;
        color: #212526;
        background-color: #ebebeb;
        border-radius: 2px;
        margin: .3rem;
        flex:0 0 28% !important;
  
        &--hasActive,
        &--active,&.highlight {
          color: $white;
          background-color: $kt-color-primary-blue;
  
          &:enabled:hover,
          &:enabled:focus {
            background-color: $kt-color-primary-blue;
            color: $white;
          }
        }
      }

      .react-calendar__navigation {
        margin-bottom: 0.5rem;
        height: 1.5rem;

        &__next2-button, &__prev2-button {
          display: none;
        }

        &__arrow {
          font-size: 1.5rem;
          color: $kt-color-primary-blue;
        }

        &__label {
          font-size: 1.2rem;
          color: #212526;
        }
        button {
          font-weight: 600;
          min-width: 4rem;
          padding: 0;
        }
      }

      &__month-view__weekdays,
      &__month-view__days {
        font-size: 1rem;
      }
    }

    .buttonContainer {
      position: absolute;
      left: 120px;
      top: 32px;

      & .ui.button {
        font-weight: 600;
        min-width: 2rem;
        letter-spacing: 0.5px;
        padding: 0.3rem 0.7rem;
      }
    }
  }

  .filterContainer {
    margin-top:0.2rem;
    width: 7.5rem;
    min-height: 2rem;
    transform: matrix(1, 0, 0, 1, 0, 0);
    text-align: left;
    color: $kt-heading-color;
    opacity: 1;
    font-size: 1.1rem;
    border-radius: 3px;
    border: 1px solid $kt-heading-color;
    display: flex;
    cursor: pointer;
    background-color: $white;
    &.errorMessage{
      border: 1px solid #FF0000;
    }
    .value{
      flex:1;
      padding:0.3rem;
      border-right:1px solid $kt-heading-color;
    }
    .iconContainer{
      justify-content: center;
      align-items: center;
      &:hover,&.active{
        background-color: #e7f6ff;
      }
    }
    i.icon.outline{
      font-size: 1.3rem;
      left: 0;
      &:hover{
        color: $kt-heading-color;
      }
      &::before{
        font-size: inherit;
      }
    }
  }
}

@import 'src/resources/css/theme/KtCommonColors';

.chart__table {
  &__header {
    background-color: $gray-border-color;
    display: flex;
    font-size: 1.2rem;
    font-family: tahoma, sans-serif;
    font-weight: bold;
    color: $kt-color-primary-blue;

    div {
      padding: 0.5rem 1rem;
      border-right: 1px solid rgb(34 36 38 / 10%);
      width: 7%;

      &.first_header {
        width: 35%;
      }

      &:hover {
        background-color: rgb(0 0 0 / 5%);
        font-weight: 600;
      }
    }

    :last-child {
      width: 38%;
    }
  }

  .table_row {
    display: flex;
    width: 100%;
    font-size: 1.2rem;
    font-family: Tahoma, sans-serif;
    font-weight: 500;
    color: $kt-heading-color;
    vertical-align: middle;
    border-bottom: 3px solid $gray-top-light;
    background-color: $white;
    .chart_tooltip {
      padding: 1rem !important;
      border: 1px solid #21bf61;
      height: 4rem;
    }

    .first_columns {
      word-break: keep-all;
      vertical-align: top;
      text-align: left;
      display: flex;
      padding: 1rem 1rem 1rem 0;
      // &.collapsed{
      //   white-space: nowrap;
      //   width: 15rem;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }

      &.sub_topic {
        width: 11%;
        padding-left: 1rem;
      }

      &.case_charges,
      &.theo_charges {
        width: 7%;
        justify-content: left;
        padding-left: 1rem;
      }
    }

    .collapsed_blank_columns {
      width: 35%;
      &.member{
        width: 41%;
      }
    }

    .second_columns {
      .bar_graph_columns {
        display: flex;
      }
    }

    &.total {
      &.blue .first_columns {
        background-color: $kt-color-secondary-blue;
        color: $white;
      }

      &.grey .first_columns {
        background-color: $gray-top-light;
        color: $kt-color-primary-blue;
      }
      
      .total_columns {
        padding-left: 1rem;
      }
    }
  }

  .bar_chart {
    display: flex;
    border: none;
    padding: 1rem 0.5rem 0.5rem;
    width: 44%;

    &.grey{
      background-color: $gray-top-light;
    }

    .businessToolTip {
      text-align: center;
      position: relative;
    }

    &_left {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &_right {
      width: 90%;
      display: flex;
      align-items: center;
    }

    .totalPercentage {
      margin-left: 0.5rem;
      font-weight: 600;
      font-size: 1.1rem;
    }

    div {
      height: 2rem;

      &.caseBillability {
        color: $white;
      }

      &.clientWithout {
        color: $white;
      }

      &.innovationFund {
        color: $white;
      }

      &.total {
        width: 10%;
      }
    }
  }
}

.ftChartWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  // padding-top:1rem;

  .chartContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    .progressItemContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      font-size: 1rem;
      font-family: tahoma, sans-serif;
      padding: 0.3rem 0.5rem;
      align-items: center;
      color: rgb(12 40 72 / 70%);

      .progressTitle {
        width: 32%;
        text-align: left;

        &.width100 {
          width: 100%;
        }
      }

      .bold {
        font-weight: bold;
      }

      .progressTitle.bold,
      .progressValue.bold {
        font-size: 1.1rem;
        font-family: tahomabd, sans-serif;
        color: rgb(52 85 124 / 70%);
      }

      .addPadding {
        padding: 0.9rem 0 0.9rem 0.1rem;
      }

      .addPaddingToValue {
        padding: 0.9rem 0.1rem 0.9rem 0;
      }

      .ftProgressBar {
        width: 50%;
        height: 6px;
        background-color: transparent;

        // border: 1px solid rgba(0, 0, 0, 0.1);

        .progress-bar {
          background-color: #3bc34e;
          border-radius: 0.25rem !important;
        }
      }

      .progressValue {
        width: 18%;
        text-align: right;
      }
    }

    .highlighted {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      height: 3.2rem;
      background: rgb(95 111 123 / 9%);
      border-radius: 3px;
    }
  }

  .legendContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: rgb(12 40 72 / 70%);
    padding-top: 0.3rem;
    border-top: 1px solid rgb(0 0 0 / 10%);

    .pL05 {
      padding-left: 0.5rem;
    }

    .legendItemContainer {
      display: flex;
      font-size: 1.1rem;
      font-family: tahoma, sans-serif;
      padding: 1px 5px;

      .legendItemLabel {
        width: 90%;
        font-size: 1rem;
      }

      .legendItemValue {
        width: 10%;
        text-align: right;
      }
    }
  }
}

.rich-text-editor-wrapper {
  .rdw-link-modal {
    height: auto;
  }

  .rich-text-editor-area {
    min-height: 20rem;
    height: auto;
    background: none;
    color: #44574e;
    font-family: Inter, sans-serif;
    font-size: 1.4rem;
    border: 0.1rem solid #68776f;
    border-radius: 0.3rem;
    max-height: 20rem;
  }

  .public-DraftEditorPlaceholder-inner {
    color: #c6ccc9 !important;
    opacity: 1;
  }

  .rdw-editor-toolbar {
    border-bottom-color: #d4e0d9;

    .rdw-image-modal {
      height: 21rem;
      padding: 0.5rem 1.5rem;

      .rdw-image-modal-header {
        font-size: 1.4rem;
        margin: 0;
      }

      .rdw-image-modal-upload-option {
        margin: 0.5rem 0;
        padding: 0.5rem 0;
        font-size: 1.2rem;
        word-break: break-word;
        overflow: hidden;

        .rdw-image-modal-upload-option-label {
          padding: 0.5rem;

          .rdw-image-modal-upload-option-image-preview {
            max-height: 5rem;
          }
        }
      }

      .rdw-image-modal-btn-section {
        margin: 0.5rem auto 0;
      }
    }
  }

  .rdw-link-modal-target-option {
    display: none;
  }

  .rdw-colorpicker-modal-options {
    margin: 5px auto;
    display: flex;
    width: 16rem;
    max-height: 20rem;
    flex-wrap: wrap;
    overflow: auto;
    height: auto;

    .rdw-colorpicker-option {
      margin: 3px;
      padding: 0;
      min-height: 1.5rem;
      border: none;
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
      box-shadow: 1px 2px 1px #bfbdbd inset;

      .rdw-colorpicker-cube {
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid #f1f1f1;
      }
    }
  }
}

.unknownRoutes {
  position: fixed;

  .error-404 {
    height: 4.2rem;
    opacity: 0.4;
    color: #33423b;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    line-height: 4.2rem;
  }

  .oops {
    color: #33423b;
    font-size: 9.2rem;
    font-weight: 800;
    letter-spacing: -0.61rem;
    line-height: 11.1rem;
  }
}

.unauthorized {
  display: flex;
  align-items: center;

  .error-404 {
    height: 2rem;
    opacity: 1;
    color: red;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    line-height: 2rem;
  }

  .oops {
    color: #33423b;
    font-size: 7rem;
    font-weight: 800;
    letter-spacing: -0.61rem;
    line-height: 8rem;
  }

  .clear-cache {
    height: 40rem;
  }
}

.unknownRoutes,
.unauthorized {
  padding: 1.2rem 12rem;

  .col-sm-7,
  .col-sm-5 {
    padding-left: 0;
    padding-right: 0;
  }

  .we-cant-seem-to-find {
    color: #33423b;
    font-size: 3.2rem;
    letter-spacing: -0.08rem;
    line-height: 4.2rem;
    margin-top: 3rem;
  }

  .unauthorized-access-message {
    color: #33423b;
    font-size: 2rem;
    letter-spacing: -0.08rem;
    line-height: 4.2rem;
    margin-top: 3rem;

    ol {
      li {
        font-size: 1.8rem;
        line-height: 3rem;
        margin: 1rem 0;
      }
    }
  }

  .button-404 {
    margin-top: 4.8rem;
    margin-left: 0;
    display: flex;

    .home-page-404 {
      height: 4rem;
      border-radius: 0.6rem;
      background-color: #197a56;
      cursor: pointer;
      color: #fff;
      padding: 0 0.5rem;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 4rem;
      text-align: center;
    }

    .get-in-touch {
      box-sizing: border-box;
      height: 4rem;
      border: 1px solid #095e36;
      border-radius: 0.6rem;
      color: #197a56;
      width: 25rem;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 4rem;
      text-align: center;
      padding: 0 0.5rem;
      cursor: pointer;

      a {
        color: #197a56;

        &:hover {
          color: #197a56;
          text-decoration: none;
        }
      }
    }
  }

  .image-404 {
    width: 76rem;
    height: 57.2rem;
  }

  .figure-401 {
    margin: 3rem 6rem;

    .image-401 {
      height: 30rem;
      width: 30rem;
    }
  }
}

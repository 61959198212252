.chart-container {
  width: 100%;
  height: 80%;
  position: relative;
  margin-top: 0.3rem;
}

.metricChartHeading {
  font-weight: bold;
  font-family: tahomabd, sans-serif;
  font-size: 1.1rem;
  line-height: 1.3rem;
  color: rgb(52 85 124 / 70%);
  fill: rgb(52 85 124 / 70%);
}

.metricChartlabel {
  font-family: tahoma, sans-serif;
  font-size: 1rem;
  line-height: 1.4rem;
  fill: rgb(52 85 124 / 80%);
  color: rgb(52 85 124 / 80%);

  // font-weight: bold;
}

.highcharts-axis-labels.highcharts-xaxis-labels > span {
  left: 0 !important;
}

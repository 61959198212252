@import 'src/resources/css/theme/KtCommonColors';

div.download-container {
  > .buttonContainer {
    display: block;
    margin-right: 0;

    .iconContainer i.icon:hover {
      color: $white;
    }

    .ui.button:hover .icon {
      opacity: 1;
    }

    .download-container__btn {
      background-color: $kt-color-primary-blue;
      color: $white;
      width: fit-content;
      display: flex;
      padding: 0.5rem;
    }
  }
}

.ui.page.dimmer > .download-modal {
  font-family: tahoma, sans-serif;
  height: max-content;
  height: max-content;
  left: auto;
  top: auto;
  width: 50%;

  .download-modal__header {
    border-bottom: 0;
    font-size: 1.3rem;
  }

  .download-modal__content__info {
    font-size: 1.2rem;
  }

  .download-modal__footer {
    display: flex;
    justify-content: flex-end;
    font-size: 1.2rem;
    font-family: tahoma, sans-serif;

    &__cancel-btn {
      background-color: transparent;
      border: 1px solid $kt-color-clear-blue;
      color: $kt-color-clear-blue;
      width: fit-content;
      padding: 1rem;
    }

    &__download-btn {
      background-color: $kt-color-secondary-blue;
      border: 1px solid $kt-color-secondary-blue;
      color: $white;
      width: fit-content;
      padding: 1rem;
      margin-left: 0.5rem;
    }
  }
}

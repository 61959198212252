@import 'src/resources/css/theme/KtCommonColors';
.active_filters_container{
    display: flex;
    align-items: center;
    margin:1rem 0;
    color: $kt-heading-color;
    font-size: 1.1rem;
    font-weight: 600;
    .view-icon{
        height: 1.2rem;
        margin-right: .5rem;
    }
    .calendar-icon{
        height: 1rem;
        margin-right: .5rem;
    }
    .heading{
        display: flex;
        align-items: center; 
    }
    .date-container,.view-container{
        margin-right: 2rem;
        border:1px solid $border-color;
        padding: 0 0.5rem;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
        background-color: $white;
        .dropdown-selected-option{
            display: flex;
            align-items: center;
            .i.icon{
                height: auto;
            }
        }
        .kt-drop-down-container{
            padding:0;
            border:none;
            .dropdown{
                .dropdown-toggle{
                    font-size: 1.1rem;
                    line-height: 1.2rem;
                    padding: 0;
                    margin-top: -.3rem;
                    color: $kt-heading-color;
                    font-family: tahoma, sans-serif;
                }
            }
            .dropdown-menu.show{
                margin:0.2rem 0 0 -2.5rem !important;
                width: 160%;
                .dropdown-item{
                    color : $kt-heading-color;
                    font-size: 1.1rem;
                    line-height: 1rem;
                    padding:1rem;
                }
            } 
        }
    }
}
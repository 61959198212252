.metric_chart_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  .metric_charts {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 30px);
    justify-content: space-between;
    overflow: auto;
    position: relative;
  }

  .legends {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .metric_legends {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: 30px;

      &.work-split {
        width: 100%;
        margin-left: 2%;
      }

      &.case-engagement {
        width: 50%;
      }

      .metric_legend_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 0.8rem;

        .legend-icon {
          width: 8px;
          height: 8px;
          content: '';
          border-radius: 100%;
          margin-right: 0.3rem;
        }

        .heading {
          font-size: 1rem;
          font-family: tahoma, sans-serif;
          line-height: 1.4rem;
        }
      }
    }
  }

  .chart-no-record-txt {
    justify-content: center;
    align-items: center;
    color: rgb(12 40 72 / 70%);
  }

  .total_back_case {
    background: #f2f2f2;
    width: 70%;
    position: absolute;
    left: -2%;
    top: 0;
    height: 30px;
  }

  .total_back_metric {
    background: #f1f1f1;
    width: 30%;
    position: absolute;
    left: 70%;
    top: 0;
    height: 30px;
  }

  .total_case_metric {
    background: #f1f1f1;
    width: 36%;
    position: absolute;
    left: 64%;
    top: 0;
    height: 30px;
  }
}

@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'fonts';

:root {
  --font-size: 16px;

  .KTDashboard-home {
    min-height: 100vh;
    .defaultMargin {
      margin: 0 auto;
      width: fit-content;
    }
    .marginWithMenu {
      margin-left: 30rem;
      width: calc(100% - 30rem);
    }
    .defaultMargin:has(> .tableau-edit-container) {
      width: 100%;
    }
    .marginWithMenu:has(> .tableau-edit-container) {
      margin-left: 29rem;
      width: calc(100% - 29rem);
    }
  }
}

@import 'theme/index';
@import 'common.scss';
.cursor-pointer {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.m10 {
  margin: 1rem;
}

.mL10 {
  margin-left: 1rem;
}

.mR10 {
  margin-right: 1rem;
}

.mB10 {
  margin-bottom: 1rem;
}

.mT10 {
  margin-top: 1rem;
}

.mT20 {
  margin-top: 2rem;
}

.p10 {
  padding: 1rem;
}

.pL10 {
  padding-left: 1rem;
}

.pR10 {
  padding-right: 1rem;
}

.pB10 {
  padding-bottom: 1rem;
}

.pT10 {
  padding-top: 1rem;
}

.pR20 {
  padding-right: 2rem;
}

.pR15 {
  padding-right: 1.5rem;
}

.pR0 {
  padding-right: 0;
}

.floatRight {
  float: right;
}

.tableauLoader {
  position: absolute;
  align-items: center;
  .tableau-logo {
    height: 4rem;
    width: 4rem;
  }
}

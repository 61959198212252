@import 'src/resources/css/theme/KtCommonColors';

.Kt_dashboard_header {
  background-color: $kt-color-secondary-blue;
  color: $white;
  position: fixed;
  width: 100%;
  z-index: 2;
  justify-content: center;
  .marginR50{
    margin-right: 5.5rem;
  }
  &.ui.grid {
    padding: 0.1rem 1.5rem;
    align-items: center;

    .fit-content {
      width: fit-content !important;
      border-bottom: 4px solid transparent;

      &.active,
      &:hover {
        border-bottom: 4px solid $white;
      }
    }

    > .column:not(.row) {
      padding: 0;
    }

    .rightAlign {
      text-align: right;
      &.displayFlex{
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .timeperiod {
        font-size: 1rem;
        font-weight: 400;
        padding-left: 0.5rem;
      }
    }

    .heading-with-dd {
      display: flex;
      align-items: center;
      border-bottom: 4px solid transparent;
      margin: 0 1rem;
      padding-right: 1.5rem !important;

      &.active,
      &:hover {
        border-bottom: 4px solid $white;
      }

      &_nabs {
        padding: 0.5rem 0 0.5rem 0.5rem;
        border-bottom: 4px solid transparent;

        &:hover,
        &:visited {
          color: $white;
          text-decoration: none;
        }
      }

      img {
        height: 1rem;
        width: 1rem;
        cursor: pointer;
      }

      .rotate180 {
        rotate: 180deg;
      }

      .billing_options {
        background-color: $white;
        width: 13rem;
        position: absolute;
        top: 4rem;
        box-shadow: 0 0.2rem 1rem 0 rgb(0 0 0 / 20%);

        &__option {
          color: $kt-color-secondary-blue;
          font-size: 1.3rem;
          font-weight: 500;
          cursor: pointer;
          padding: 1rem 1rem 1rem;
          &:hover{
            font-weight: 600;
            background-color: #e7f6ff;
          }
          &:last-child{
            padding-bottom: 1.5rem;
          }
        }
      }
    }
  }

  &_logo {
    width: 100%;
    cursor: pointer;
    margin: .5rem 0;
  }

  &_nabs {
    color: $white;
    line-height: 3rem;
    font-size: 1.3rem;
    padding: 0.5rem 1.5rem;
    display: inline-block;
    text-decoration: none;
    margin: 0 1rem;
    cursor: pointer;
    border-bottom: 4px solid transparent;
  }

  &_timeStamp {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.kt-member-rectangle {
  background-color: #fff;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  font-family: tahoma, sans-serif;
  position: relative;
  width: 100%;
  height: 3.2rem;
  border: 1px solid #a0a9b480;
  padding: 0.5rem 1rem;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  .lds-ellipsis {
    top: 0 !important;
    margin-right: 2rem;

    div {
      background: #114583;
    }
  }

  .kt-member-container {
    width: 95%;
    padding: 0 0.5rem 0 0;

    .kt-member-input {
      color: #4f5b6a;
      font-size: 1rem;
      font-weight: bold;
      border: none;
      width: 100%;

      &:focus,
      &:hover {
        outline: none;
      }
    }

    .kt-img-icon {
      width: 1.5rem;
      height: 1.5rem;
      display: inline-block;
      cursor: pointer;
      background-repeat: no-repeat;
    }

    ::placeholder {
      color: #4f5b6a !important;
      opacity: 1;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.8rem;
    }
  }
}

.kt-member-result-container {
  max-height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: none;
  position: absolute;
  top: 3.3rem;
  left: 0;
  width: 100%;
  box-shadow: 0 3px 6px #00000029;
  background: #fff 0% 0% no-repeat padding-box;
  margin-left: 0.1rem;
  z-index: 2;

  .kt-member-result-list {
    .kt-searched-item {
      cursor: pointer;
      height: 3.5rem;
      color: #4f5b6a;
      font-weight: bold;
      font-size: 1rem;
      letter-spacing: 0;
      padding: 1.5rem;
      text-align: left;

      &.disabled {
        cursor: default;
        pointer-events: none;
      }
    }
  }
}

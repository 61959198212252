@import 'src/resources/css/theme/KtCommonColors';

.iconContainer {
  display: -webkit-box;
  display: flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  &.rotate90{
    rotate: 90deg;
  }

  div {
    vertical-align: top;
  }

  i.icon {
    cursor: pointer;
    margin: 0 0.2rem;
    height: auto;

    &.small {
      font-size: 1em;
    }

    &.tiny {
      font-size: 0.8em;
    }

    &.large {
      font-size: 1.25em;
    }

    &.huge {
      font-size: 1.5em;
    }

    .invert {
      background-color: $bcg-green;
      color: $white;
    }

    &.green {
      color: $bcg-green;
    }

    &.gray {
      color: $gray-dark;
    }

    &:hover {
      color: $bcg-green;
    }
  }

  span {
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 0.001px;
  }
}

.info-icon {
  fill: #5c7796;
  background-image: none;
  margin-left: 0.5rem;
  padding-top: 0.3rem;
  width: 1.8rem;
  height: 1.8rem;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.tooltip-with-icon {
  &.positionRelative {
    position: relative;
  }

  cursor: pointer;

  > img {
    cursor: pointer;
  }

  > svg {
    cursor: pointer;
    fill: #5c7796;
    background-image: none;
  }

  &.areaMargin {
    margin: 2rem 0.5rem 0 0;
  }

  &.ddlMargin {
    margin: -0.5rem 0.5rem 0 1.5rem;
  }

  &.homeTileToolTip {
    position: absolute;
    margin: 0.3rem 0.4rem 0 0;
    display: flex;
    justify-content: flex-end;
    bottom: 1rem;
    right: 0;
  }

  &.periodTooltip {
    position: relative;
    cursor: default;
  }
}

#tooltip {
  position: absolute;
  width: max-content;
  top: 2rem;
  z-index: 2;
  display: none;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);

  &.on {
    display: block;
  }

  &.onRight {
    display: block;
    right: 1rem;
  }

  &.onLeft {
    display: block;
    left: 11rem;
  }

  .tooltip-inner {
    font-size: 1.1rem;
    color: #000;
    background-color: #fff;
    text-justify: inter-word;
    font-weight: normal;
    text-align: left;
    font-family: tahoma, sans-serif;
  }
}

@media screen and (-ms-high-contrast: none) {
  .tooltip-with-icon {
    > img {
      height: 22px;
    }
  }
}

@supports (-ms-ime-align: auto) {
  .tooltip-with-icon {
    > img {
      height: 22px;
    }
  }
}

@import 'src/resources/css/theme/KtCommonColors';

.ui.page.dimmer > .charges_hours_modal {
  left: auto;
  right: auto;
  width: 75%;
  top: auto;
  font-family: tahoma, sans-serif;
  height: 50rem;
  color: $kt-heading-color;
  overflow: auto;

  .charges_hours_modal_left_section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  .charges_hours_modal_right_section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }

  > .header {
    display: flex;
    align-items: center;
    border: none;
    font-size: 1.4rem;

    .download-icon {
      height: 1rem;
      width: 1rem;
    }

    .back_button {
      cursor: pointer;

      .back_text,
      .back_arrow {
        font-size: 1.2rem;
        color: $kt-color-primary-blue;
      }
    }

    .heading_text {
      margin-left: 1rem;
    }
  }

  > .content {
    padding-top: 0;
    padding-bottom: 0.5rem;

    .top_section {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      .week_posted_date {
        font-weight: 600;
        font-size: 1.1rem;

        div {
          font-weight: 400;
          padding-top: 0.25rem;
        }
      }

      .week_dropdown {
        .kt-drop-down-container {
          padding: 0;

          &.show {
            border: none;
            box-shadow: none;
          }

          .dropdown {
            .dropdown-toggle {
              font-size: 1.1rem;
              color: $kt-heading-color !important;
              font-family: tahoma, sans-serif;
              line-height: 1rem;
              padding: 0;
              background-color: transparent;
            }
          }

          .dropdown-menu.show {
            width: 100%;
            padding: 0;
            line-height: 1rem;
            background-color: $white;
            border: none;
            border-right: 0.1rem solid hsl(0deg 0% 100% / 14.9%);
            box-shadow: 0 0.2rem 1rem 0 #0003;

            .dropdown-item {
              padding: 1rem 0.8rem;
              color: $kt-heading-color;

              &:hover {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}


  .user_details_container{
    background-color: #fff;
    box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.2);
    min-height: 100vh;
    padding:1.5rem;
    color:#212529;
    .name_section{
      margin-bottom: 1rem;
      align-items: center;
      display: flex;
      .name{
        font-size: 1.5rem;
        flex:1;
        width: 50%;
      }
      .last_updated_date{
        justify-content: flex-end;
        display: flex;
        font-size: .9rem;
        flex:1;
        width: 50%;
      }
    }
    .user_details_section{
      border-bottom: 2px solid #d6d6d6;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      .filter-display__radio{
        font-weight: 800;
      }
      .text{
        margin-left:1.8rem;
      }
      .user_details{
        margin-bottom: .5rem;
        font-size: 1rem;
        font-weight: 800;
      }
      .user_details_row{
        display: flex;
        margin-top:1rem;
        .jobTitle,.hireDate{
          flex:1;
          width: 50%;
        }
        .hireDate,.model,.geo_region,.system,.home_office{
          margin-left: 1rem;
        }
        .model,.geo_region,.region,.system{
          width: 30%;
        }
        .path,.home_office{
          width: 40%;
        }
        .pa,.topic,.subTopic{
          flex:1;
          width: 100%;
        }
        .details_heading{
          font-size: 0.9rem;
          font-weight: 400;
        }
        .details_value{
          background-color: #F2F2F2;
          padding:.5rem 1rem;
          height: 2.5rem;
          width: 100%;
          margin-top:.5rem;
          border-radius: 5px;
          font-weight: 500;
        }
     }
   }
  }


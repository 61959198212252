@import 'src/resources/css/theme/KtCommonColors';

.kt-drop-down-container {
  display: inline-block;
  padding-top: 0.6rem;
  text-align: left;
  height: auto;
  border: 0.1rem solid transparent;
  width: auto;
  border-bottom: 0;
  align-items: center;

  &.show {
    box-shadow: 0 0.1rem 0.5rem 0 $kt-color-secondary-3-black;
    background-color: $white;
    border-color: $kt-color-secondary-6-black;
    border-bottom: 0;
    .dropdown {
      .dropdown-toggle {
        &::after {
          rotate: 180deg;
          margin-top: -0.2rem;
          height: 1.2rem;
          width: 1.2rem;
        }
      }
    }
  }

  &.not-clickable {
    pointer-events: none;
  }

  .dropdown-heading {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    padding-left: 1.2rem;
    color: $kt-color-secondary-2-black;
  }

  .dropdown {
    padding-bottom: 0;

    .dropdown-toggle {
      width: 100%;
      border: none;
      color: $kt-color-primary-blue;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.8rem;
      background-color: transparent;
      text-align: left;
      padding-left: 1.2rem;
      padding-bottom: 1.18rem;
      white-space: normal;
      font-family: tahomabd, sans-serif;

      &:hover {
        background-color: transparent;
        color: $kt-color-primary-blue;
      }

      &:focus,
      &:active {
        border: none !important; // over writing the inline style of drop down list
        box-shadow: none !important; // over writing the inline style of drop down list
        outline: none !important; // over writing the inline style of drop down list
        background-color: transparent !important; // over writing the inline style of drop down list
        color: $kt-color-primary-blue !important; // over writing the inline style of drop down list
      }

      &::after {
        background-image: url('../../../resources/img/kt-scorecard-down-arrow.svg');
        width: 1.2rem;
        height: 1rem;
        vertical-align: middle;
        border: none;
        background-repeat: no-repeat;
      }
    }
  }

  .dropdown-no-label {
    .dropdown {
      padding-bottom: 0;

      .dropdown-toggle {
        width: 100%;
        border: none;
        background-color: transparent;
        text-align: left;
        vertical-align: middle;
        color: $white;
        font-size: 1.1rem;
        line-height: 1.4rem;
        margin: 0;
        font-family: tahomabd, sans-serif;

        &:hover {
          background-color: transparent;
          color: $white;
        }

        &:focus,
        &:active {
          border: none !important; // over writing the inline style of drop down list
          box-shadow: none !important; // over writing the inline style of drop down list
          outline: none !important; // over writing the inline style of drop down list
          background-color: transparent;
          color: $white;

          &::after {
            background-image: url('../../../resources/img/Down_arrow_2.svg');
            width: 1.2rem;
            height: 1.1rem;
            vertical-align: unset;
            border: none;
            background-repeat: no-repeat;
          }
        }

        &::after {
          background-image: url('../../../resources/img/Down_arrow_2.svg');
          width: 1.2rem;
          height: 1.1rem;
          vertical-align: unset;
          border: none;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .dropdown-menu.show {
    box-shadow: $kt-color-secondary-3-black 0 1px 5px 0;
    color: $kt-color-primary-blue;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.3rem;
    padding-top: 0;
    top: unset !important; // over writing the inline style of drop down list
    transform: unset !important; // over writing the inline style of drop down list
    border: 0.1rem solid $kt-color-secondary-6-black;
    border-top: 0 solid $kt-color-secondary-black;
    position: absolute;
    margin-top: 0.5rem !important;
    border-radius: 0;
    width: 22rem;
    margin-left: -0.1rem;
    z-index: 1 !important; // over writing the inline style of drop down list
    overflow: auto;
    max-height: 20rem;

    .dropdown-item {
      color: $kt-color-blue;
      font-size: 1.1rem;
      line-height: 1.4rem;
      padding: 1.2rem;
      width: auto;
      white-space: unset;

      &:hover {
        background-color: $kt-color-off-white;
      }

      &:focus,
      &:active {
        border: none;
        outline: none;
        background: transparent;
      }

      &:first-child {
        border-top: 0.1rem solid $kt-color-secondary-black;
      }
    }
  }

  .dropdown-hide .dropdown-menu {
    display: none;
  }
}
@import 'src/resources/css/theme/KtCommonColors';
.reactCustomCheckbox {
    display: block;
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $kt-heading-color;
    margin-bottom: 0;
    &:hover{
      font-weight: 600;
      background-color: #E7F6FF;
    }
    label{
      cursor: pointer;
      margin-bottom: 0;
    }
  
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  
    .checkmark {
      position: absolute;
      top: .3rem;
      left: 0;
      height: 1.2rem;
      width: 1.2rem;
      border: 1px solid $kt-color-secondary-blue;
      background-color: $white;
      &.partialChecked {
        padding: 2%;
        background-color: $kt-color-secondary-blue;

        .partialCheckContainer {
          width: 90%;
          height: 90%;
          background-color: $white;
        }
      }
    }
  
    input:checked~.checkmark {
      background-color: $kt-color-secondary-blue;
    }
  
    input:checked~.checkmark:after {
      display: block;
    }
  
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 3px;
      top: 0px;
      width: 5px;
      height: 7px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
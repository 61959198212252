@import 'src/resources/css/theme/KtCommonColors';

.ui.popup.custom-tooltip {
  .column {
    text-align: left;
    line-height: 1;
    margin: 3px !important; // We are overriding the css of default semantic grid
  }

  &.single {
    width: max-content;
    max-width: 100px;

    .valueGrid.ui > .column:not(.row) {
      max-width: 100%;
    }
  }

  &.double {
    width: 180px;

    .valueGrid.ui > .column:not(.row) {
      max-width: 45%;
    }
  }

  &.triple {
    width: 250px;

    .valueGrid.ui > .column:not(.row) {
      max-width: 30%;
    }
  }

  &.double-grid {
    width: 250px;

    > .grid {
      width: 100%;
      padding: 0;
    }
  }

  &.d-none {
    display: none !important; // As we want to override the framework css to cater the edit mode for material in process
  }
}

div.ui.top.left.large.inverted.popup.visible.transition.toggleButtonPopup {
  // set according to FilterSection->right_align->toggle
  top: -150px !important;
}

.ui.top.popup,
.ui.bottom.popup {
  font-size: 0.8rem;
  cursor: pointer;

  p {
    padding: 0;
    margin: 0;
  }
}

.ui.inverted.popup {
  .buttonContainer .ui.button.link {
    color: $tooltip-link-green;
  }
}

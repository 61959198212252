// kt-scorecard colors
$kt-disabled-color:#bfc7d0;
$kt-late-text-color:#6b0000;
$kt-checkbox-color:#c1cad5;
$kt-name-text-color:#4f5b6a;
$kt-button-border-color:#BFBFBF;
$kt-button-background-color:#F5F8FA;
$kt-brick-color:#734c00;
$kt-color-light-yellow:#ffdf7f;
$kt-color-highlight-yellow:#fff2c2;
$kt-color-light-red:#ffbcbc;
$kt-color-blank:#e4e4e4;
$kt-hover-color:#597494;
$kt-active-color:#0774C1;
$kt-heading-color:#212529;
$kt-light-grey-color: #f0f3f5;
$kt-color-button-blue: #1a699e;
$kt-color-secondary-blue: #113865;
$kt-color-clear-blue: #464646;
$kt-color-primary-blue: #114583;
$kt-color-light-blue: #6bb8e7;
$kt-color-secondary-green: #32b091;
$kt-color-inactive-gray: #a7a7a7;
$kt-color-active-black: #000;
$kt-color-primary-dark-blue: #0c2848;
$kt-color-primary-darkest-1-blue: rgb(12 40 72 / 90%);
$kt-color-primary-darkest-2-blue: rgb(12 40 72 / 50%);
$kt-color-primary-light-blue: #8ca5ca;
$kt-color-secondary-light-blue: #98dbca;
$white: #fff;
$kt-color-off-white: #e7f6ff;
$kt-color-off-white-1: rgb(255 255 255 / 80%);
$kt-color-green: #3bc34e;
$kt-color-red: #da1b1b;
$kt-color-orange: #f99900;
$kt-color-light-gray: #eef1f5;
$kt-color-light-gray-1: #bebebe;
$kt-color-light-gray-2: #b6b6b6;
$kt-color-blue: rgb(12 40 72 / 70%);
$kt-color-blue-1: rgb(12 40 72 / 60%);
$kt-color-secondary-black: rgb(0 0 0 / 5%);
$kt-color-secondary-1-black: rgb(0 0 0 / 85%);
$kt-color-secondary-2-black: rgb(0 0 0 / 75%);
$kt-color-secondary-3-black: rgb(0 0 0 / 10%);
$kt-color-secondary-4-black: rgb(0 0 0 / 50%);
$kt-color-secondary-5-black: rgb(0 0 0 / 40%);
$kt-color-secondary-6-black: rgb(0 0 0 / 20%);
$kt-color-dark-blue: rgb(52 85 124 / 70%);
$kt-color-light-purple: rgb(70 93 138 / 70%);
$kt-color-border-blue: #839fb7;
$kt-color-active-tab: #048;
$kt-color-edit-page: #071627;
$kt-color-tile-blue: #5c7796;
$kt-color-darkest-blue: #0c284880;
$kt-color-gray-blue: rgb(70 93 138 / 70%);
$filter-text-color: #3d3d3d;
$filter-text-color: #3d3d3d;
$bcg-green: #258a12;
$light-green: #20b191;
$color-green: #007742;
$tooltip-link-green: #32c77f;
$color-blue: #00e;

// Grayscale
$black: #000;
$gray-semi-dark: #ccc;
$gray: #d9d9d9;
$gray-darker: #707070;
$gray-dark: #b7b7b7;
$gray-light: #f2f2f2;
$gray-lighter: #f1f1f1;
$gray-lightest: #fafafa;
$transparent: rgb(0 0 0 / 0%);
$gray-top-light: #ebebeb;
$gray-border-color: #d6d6d6;

//borders
$border-color:#A6A6A6;

.kt-m-checkbox {
  display: flex;
  align-items: center;

  .kt-checkbox {
    &:focus {
      outline: none;
    }
  }

  &::after {
    border: none;
    outline: none;
  }

  .unchecked {
    position: relative;
    flex-shrink: 0;
    width: 1.2rem;
    height: 1.2rem;
    appearance: none;
    background: #d8d8d8;
    cursor: pointer;
  }

  .checked {
    position: relative;
    flex-shrink: 0;
    width: 1.2rem;
    height: 1.2rem;
    appearance: none;
    cursor: pointer;
    border: 0.1rem solid #22446c;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 3.5px;
    background-color: #22446c;
    background-repeat: no-repeat;
    background-position: center;
  }

  .dark-blue,
  .darkest-blue {
    background-color: transparent;
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: middle;

    &.unchecked {
      border: 0.2rem solid #22446c;
    }

    &.checked {
      background-color: #22446c;
      border: none;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../../resources/img/Checked.svg');
    }
  }

  .darkest-blue {
    &.unchecked {
      border-color: #0c2848;
    }

    &.checked {
      background-color: #0c2848;
    }
  }

  .light-blue {
    background-color: transparent;
    width: 1.2rem;
    height: 1.2rem;

    &.unchecked {
      border: 0.2rem solid #6bb8e7;
    }

    &.checked {
      border: none;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../../resources/img/light-blue-checked.svg');
    }
  }

  .light-green {
    background-color: transparent;
    width: 1.2rem;
    height: 1.2rem;

    &.unchecked {
      border: 0.2rem solid #98dbca;
    }

    &.checked {
      border: none;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../../resources/img/light-green-checked.svg');
    }
  }

  .checkbox-label {
    flex-shrink: 0;
    padding: 0 0.4rem;
    color: #22446c;
    font-size: 1.2rem;
    line-height: 2.4rem;
    margin: 0;
  }
}

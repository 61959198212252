@import 'src/resources/css/theme/KtCommonColors';

.peopleImage {
  &_container {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.cursorPointer {
      cursor: pointer;
    }

    &.selected {
      border: 2px solid $color-green;
    }

    &.primary {
      border: 2px solid $white;
    }
  }
}

.thumbnail_text {
  padding: 0.45rem;
  color: rgb(122 134 154);
  border-radius: 50%;
  background-color: $gray;
  margin-left: -0.5rem;
  z-index: 1;
  cursor: pointer;

  &:hover {
    z-index: 2;
    transform: scale(1.1);
  }
}

.profileImg {
  width: 2rem;
  height: 2rem;
}

.kt-search-container {
  .kt-search-rectangle-2 {
    display: flex;

    .kt-small-search-icon {
      height: 11px;
      margin-right: 1rem;
      margin-top: 2.6rem;
      &.marginLeft{
        margin-left: 1.2rem;
      }
    }

    .kt-search-input {
      background: #fff;
      line-height: 1.6rem;
      border: 0;
      outline: 0;
      width: 35.7rem;
      padding: 2.3rem 0 1.4rem;
    }
  }
}

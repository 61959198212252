@import 'src/resources/css/theme/KtCommonColors';
.error{
    color:rgb(203, 77, 77);
    padding-top: .5rem;
  }
    .collection-list{
      margin-bottom: 1rem;
      border-bottom: 1px solid #b6b6b6;
      padding-bottom: 1rem;
      .collection-item{
        color: $kt-color-secondary-blue;
        margin-bottom: 1rem;
        border: 1px solid $kt-color-secondary-blue;
        height: 2.5rem;
        border-radius: 0.5rem;
        background-color: $white;
        padding: 0.5rem 1rem 0.5rem 1rem;
        display: flex;
        align-items: center;
        position: relative;
        &:hover, &.active{
          background-color: #e7f6ff;
          cursor: pointer;
          font-weight: 600;
        }
        .collection-name{
          line-height: 2rem;
          flex:1;
        }
        .collection-icon{
          font-size: 1.3rem;
          font-weight: bolder;
          display: flex;
          cursor: pointer;
          margin-top: -.7rem;
        }
      }
      .edit-delete{
        position: absolute;
        background-color: $white;
        height: max-content;
        top:1.5rem;
        right: -1.5rem;
        z-index: 1;
        box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.2);
        .edit,.delete{
          color: #113865;
          font-size: 1.1rem;
          font-weight: 500;
          cursor: pointer;
          padding: 1rem 1rem 1rem;
          &:hover {
            font-weight: 600;
            background-color: #e7f6ff;
          }
        }
      }
    }

    .ui.page.dimmer > .save_filter_modal{
        background-color: $kt-color-off-white;
        font-family: Tahoma, sans-serif;
        width: 20%;
        height: max-content;
        top: unset;
        left: unset;
        .edit-collection{
            position: relative;
            background-color: transparent;
            box-shadow: none;
            top:0;
        }
        .save_filter_modal__header{
          border-bottom: 0;
          font-size: 1.5rem;
          .delete-icon{
            float: right;
            height: 1.6rem;
            width: 1.5rem;
            cursor: pointer;
          }
        }
      }
      
      .ui.modal>.close {
        top: 0;
        right: 0;
        color: $kt-color-primary-blue;
      }
      
      .ui.modal>.content{
        &.admin-filter-container{
          position: relative;
          top:0;
          box-shadow: none;
          padding:0 1.5rem;
          .name{
            margin-bottom: .3rem;
            font-size: 1.1rem;
          }

          .save-input{
            width: 100%;
            border: 1px solid #d6d6d6;
            font-size: 1.2rem;
            padding: .5rem;
            border-radius: 5px;
            &:focus-visible{
              outline: none;
            }
          }
          .filter-list{
            margin-top: 1rem;
            .filter-display .filter-name{
              color : #113865;
            }
          }
          .kt-m-checkbox .darkest-blue.checked{
            background-color: #22446c;
          }
        }
        
      }
      
      .ui.modal>.actions{
        &.save_filter_modal__footer{
          border: none;
          .buttonContainer .ui.button{
            background: #113865;
            color: #fff;
            border: 1px solid #113865;
            padding: 0.5rem 1rem;
            font-size: 1.1rem;
            border-radius: 4px;
            &.cancel{
              background: transparent;
              color: #464646;
              border: 1px solid #464646;
              padding: 0.5rem 1rem;
            }
            &.disabled{
              background: transparent;
              color: #464646;
            }
          }
        }
      }
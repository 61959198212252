.vertical-wrapper {
  .react-tree-search-input {
    margin-bottom: 10px;
  }

  .vertical-dropdown {
    display: flex;
    flex-direction: column;
  }
}

.vertical-options-children-container {
  padding-left: 2rem;
}

.vertical-option {
  display: flex;
  flex-direction: row;
  padding: 0.15rem 0;
  font-weight: 500;

  &.marginL20 {
    margin-left: 1.95rem;
  }

  .iconContainer {
    margin-top: 0.1rem;
    margin-left: -0.5rem;

    i.icon {
      margin: 0 0.5rem;
    }
  }

  &:hover {
    font-weight: 600;
    background-color: #e7f6ff;
  }

  img {
    height: 1.8rem;
    margin: 0 0.5rem 0 -0.5rem;
  }

  .all {
    color: #0774c1;
    font-size: 1.1rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

.button-footer {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  width: 100%;

  .apply {
    border: 1px solid $kt-color-secondary-blue;
    border-radius: 4px;
    cursor: pointer;
    width: auto;
    background: $kt-color-secondary-blue;
    color: $white;
    padding: 0.3rem 1rem;
    flex: 1;
    text-align: center;

    &.disabled {
      cursor: default;
      pointer-events: none;
      background: #bfc7d0;
      color: $white;
      border: 1px solid #bfc7d0;
    }
  }

  .clear {
    text-align: center;
    flex: 1;
    background: transparent;
    border: 1px solid #464646;
    border-radius: 4px;
    padding: 0.3rem 1rem;
    color: #464646;
    cursor: pointer;
    width: auto;
    margin-right: 1rem;
    background-color: $white;
  }
}

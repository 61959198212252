.request-satisfaction-chart {
  width: 15rem;
  height: 9rem;
  position: relative;
}

.pie_chart_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  .pie_chart {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 40%;
    justify-content: space-between;

    &.full-height {
      height: 100%;
    }
  }

  .pie_legends {
    display: flex;
    flex-direction: column;
    color: rgb(12 40 72 / 70%);
    font-family: tahoma, sans-serif;
    font-size: 10px;

    .pie_legend_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: 'Poppins Regular', sans-serif;

      .pie_badge {
        width: 5px;
        height: 5px;
        content: '';
        border-radius: 100%;
        margin: 0 10px;
      }
    }
  }
}

.piechartlegends {
  font-family: tahoma, sans-serif;
  font-size: 10px;
  color: rgb(12 40 72 / 70%);
}

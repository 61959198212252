@import 'src/resources/css/theme/KtCommonColors';
.impersonator-container{
    font-size: 1.1rem;
    font-family: tahoma, sans-serif;
    position: relative;
        cursor: pointer;
        cursor: text;
        z-index: 1;
        padding-right: 2rem;
        .impersonator {
            color: #fffc;
            font-weight: 400;
            line-height: 1.4rem;
        }
        .select-impersonator {
            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            .text {
                color: $white;
                font-family: tahomabd, sans-serif;
                line-height: 1.7rem;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .kt-drop-arrow {
                height: 10px;
                margin-left: 1rem;
                margin-top: .2rem;
                width: 12px;
            }
        }
        .list-container {
            padding: 1.2rem;
            z-index: 3;
            background-color: $white;
            border-right: .1rem solid #ffffff26;
            box-shadow: 0 .2rem 1rem 0 #0003;
            color: #0c2848b3;
            display: inline-block;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.4rem;
            padding-right: 1rem;
            position: absolute;
            right:0;
            width: 25rem;
            .disabled {
                cursor: default;
                color: $kt-color-inactive-gray;
                pointer-events: none;
            }
            .show-user-list {
                display: flex;
                margin-top: .5rem;
                max-height: 25rem;
                overflow-y: auto;
                padding-right: 1rem;
                .user-list {
                    flex: 1 1;
                    margin-top: .5rem;
                    .user-display{
                        align-items: center;
                        display: flex;
                        padding: .5rem 0;
                        .user-name {
                            color: $kt-color-secondary-blue;
                            font-size: 1.1rem;
                            text-align: left;
                        }
                    }
                }
                .selection-option {
                    .text {
                      background: $white 0% 0% no-repeat padding-box;
                      border: 1px solid #c1cad5;
                      border-radius: 4px;
                      font-size: 1rem;
                      letter-spacing: 0;
                      color: #4f5b6a;
                      padding: 0.3rem 0.5rem;
                      margin: 1rem 0;
                      cursor: pointer;
              
                      &.selected {
                        background: #d9e9f4 0% 0% no-repeat padding-box;
                      }
                    }
                  }
            }
            .button-footer {
                align-items: center;
                display: flex;
                font-size: 1rem;
                font-weight: 700;
                letter-spacing: .5px;
                margin-top: 1rem;
                .apply-button {
                    display: flex;
                    flex: 1 1;
                    justify-content: flex-end;
                    .disabled {
                        cursor: default;
                        pointer-events: none;
                    }
                }
            }
        }
}
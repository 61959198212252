@import 'src/resources/css/theme/KtCommonColors';

.buttonContainer {
  display: inline-block;
  width: auto;
  margin-right: 1rem;
  .ui.button {
    transition: all 0.3s ease;
    font-size: 1rem;
    border-radius: 0.5rem;
    letter-spacing: 1.5px;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    font-weight: 500;
    font-family: tahoma, sans-serif;

    &.primary {
      &.clear {
        background-color: #d4d2d2 !important;
        border: 1px solid #d4d2d2;
        color: $black;
      }

      &.apply {
        background-color: $light-green !important;
        border: 1px solid $light-green;
        color: $white;
      }

      width: 100%;

      &.disable {
        background-color: $gray !important;
        border: 1px solid $gray;
        pointer-events: none;
      }

      &.ghost {
        background-color: $white !important;
        color: $black;
        font-weight: 600;

        &:active {
          background-color: $white !important;
          transform: translateY(3%);
        }
      }

      &:hover {
        transform: translateY(-2%);
      }

      &:active {
        background-color: $kt-color-dark-blue !important;
        transform: translateY(3%);
      }
    }

    &.link {
      background-color: $transparent;
      color: $bcg-green;
      font-weight: normal;
      text-transform: none;
      min-width: 0;
      padding: 0;
      letter-spacing: 0;

      &:hover {
        text-decoration: underline;
      }
    }

    &.toggle {
      transition: none;
      min-width: 7.5rem;
      padding: 0.7rem 1.5rem;
      background-color: $gray-lightest;
      border: 1px solid;

      &-0 {
        border-color: $gray-lighter;
      }

      &-1 {
        border-style: inset;
        background-color: $gray-light;
      }
    }
  }
}

@import 'src/resources/css/theme/KtCommonColors';
.sort-by-container{
    &.disabled{
        background-color: $white;
    }
    .buttonContainer .ui.button{
        padding:0.5rem;
        border: 1px solid $kt-button-border-color;
        background-color: $kt-button-background-color;
        border-radius: 5px;
        letter-spacing: 1.1px;
        color: $kt-heading-color;
        font-weight: 600;
        &.active{
            background-color: $white;
        }
    }
    .singleSelectFilter{
        label{
            margin-bottom: 0;
        }
    }
    .admin-filter-container .filter-list .filter-display .filter-name{
        font-size: 1rem;
        margin-bottom: 0;
    }
    .iconContainer i.icon:hover {
        color: $kt-heading-color;
      }
}
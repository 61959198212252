.Kt_main_body {
  .rightPadding20 {
    padding-right: 2rem !important;
  }

  .admin_container {
    margin: 1rem 0 0;

    .edit {
      position: absolute;
      background-color: #fff;
      height: max-content;
      top: inherit;
      right: 0;
      z-index: 1;
      box-shadow: 0 0.2rem 1rem 0 rgb(0 0 0 / 20%);
      color: #113865;
      font-size: 1.1rem;
      font-weight: 500;
      cursor: pointer;
      padding: 1rem;
      margin-top: 1.5rem;

      &:hover {
        font-weight: 600;
        background-color: #e7f6ff;
      }
    }

    .top_section {
      display: flex;
      align-items: center;

      .missing-switch {
        display: flex;
        align-items: center;

        .missing_details {
          font-weight: bold;
          color: #212529;
        }
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 3rem;
        height: 1.2rem;
        margin: 0 0.5rem 0 1rem;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #212529;
        transition: 0.4s;
      }

      .slider::before {
        position: absolute;
        content: '';
        height: 1rem;
        width: 1rem;
        left: 0;
        bottom: 0.04rem;
        background-color: #113865;
        transition: 0.4s;
      }

      input:checked + .slider::before {
        transform: translateX(1.8rem);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 5rem;
      }

      .slider.round::before {
        border-radius: 50%;
      }
    }

    .ui.basic.table {
      border: none;
      color: #212529;
      font-family: tahoma, sans-serif;

      thead {
        font-size: 1.2rem;
        background-color: #eaeaea;

        th {
          padding: 0.5rem 0.7rem;

          &:last-child {
            border-right: none;
            border-left: none;
          }

          &.borderRightNone {
            border-right: none;
          }
        }
      }

      tbody {
        background-color: #fff;
        font-size: 1.1rem;
        font-weight: 500;
      }
    }

    .ui.sortable.table tbody tr {
      .details {
        vertical-align: top;
        border: none;
        border-bottom: 2px solid #d6d6d6;
        width: 20%;
        word-break: keep-all;
        padding: 0.5rem 0.7rem;
      }

      .employee_name,
      .employee_pa,
      .employee_sector {
        width: 20%;
      }

      .employee_role {
        width: 10%;
      }

      .employee_sub_sector {
        width: 27%;
      }

      .edit_icon {
        width: 3%;
        font-size: 2.5rem;
        padding: 0 1rem 2rem 0;
        line-height: 1rem;
        letter-spacing: -0.3rem;
        text-align: center;
        cursor: pointer;
        position: relative;
        vertical-align: middle;
      }

      .kt-info {
        height: 1rem;
        width: 1rem;
        margin-right: 0.3rem;
        margin-top: -0.2rem;
      }

      .missing {
        background-color: #f6d6d6;
        color: #f00;
      }
    }

    .chart__table {
      margin-top: 1rem;
    }
  }
}

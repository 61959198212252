.tree-dropdown {
  position: relative;
  width: 100%;

  .react-tree-menu {
    position: absolute;
    border: 1px solid #000;
    z-index: 10;
    margin-top: 5px;
    background-color: #FFF;
  }

}


@import 'src/resources/css/theme/KtCommonColors';
.flex1{
  flex:1;
}
input[type=radio]{
  accent-color: $kt-color-primary-blue;
}
.error-container{
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.9rem;
  justify-content: center;
  margin-left: 3rem;
  img{
    height: 1rem;
    width: 1rem;
  }
  .text{
    color:#FF0000;
    margin-left: .25rem;
  }
}
.date-calender-container {
  will-change: transform;
  top: 0;
  margin-top: 1rem;
  color: $kt-heading-color;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
  z-index: 1;
  height: auto;
  cursor: default;
  position: relative;
  .custom-calender{
    .enabled{
      display: flex;
      align-items: center;
    }
  }

  &.date-calender-analyst {
    position: absolute;
    margin-top: 2rem;
    background-color: $white;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 20%);
    margin-left: -0.5rem;
    padding: 1rem;
    border-radius: 0 0 3px 3px;
    width: fit-content;
    height: fit-content;
    display: flex;
    .custom-calender{
      margin-left: 2rem;
      box-shadow: none;
      border-left: 1px solid #ddd;
      padding:0.5rem 0 0 1rem;
    }
  }

  &__left {
    &__list-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0.5rem 0;

      &:hover {
        background-color: $kt-color-off-white;
        font-weight: 600;
      }

      span {
        padding: 0 0.5rem;

        input {
          width: 3rem;
          border-radius: 0.2rem;
          text-align: center;
        }
      }
      &.padding-l05 {
        padding-left: 0.5rem;
      }
    }

    .button-footer {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: 0.5px;
      justify-content: flex-end;

      .apply {
        border-radius: 4px;
        cursor: pointer;

        &.disabled {
          background: transparent;
          color: $kt-color-clear-blue;
          pointer-events: none;
          cursor: default;
        }

        background: $kt-color-secondary-blue;
        color: $white;
        border: 1px solid $kt-color-secondary-blue;
        padding: 0.3rem 1rem;
      }

      .clear {
        background: transparent;
        color: $kt-color-clear-blue;
        border: 1px solid $kt-color-clear-blue;
        padding: 0.3rem 1rem;
        cursor: pointer;
        width: auto;
        margin-right: 1rem;
        border-radius: 4px;
      }
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: -3rem;
  }

  .date-time-period-calender {
    width: 20rem;
    box-shadow: none !important;

    .react-datepicker__month-container {
      width: 100%;

      .react-datepicker__header {
        font-size: 1.5rem;
        line-height: 1rem;
      }
      .react-datepicker__month .react-datepicker__month-text {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 2rem;
        padding: 0.5rem 1rem;
      }
    }
  }
  .disabledMain {
    display: none;
  }
}

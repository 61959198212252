@import 'src/resources/css/theme/KtCommonColors';

.displayNone {
  display: none;
}

.overlay-dropDown {
  position: absolute;
  z-index: 3;
  background-color: $white;
  border-right: 0.1rem solid rgb(255 255 255 / 14.9%);
  box-shadow: 0 0.2rem 1rem 0 rgb(0 0 0 / 20%);
  padding: 0;
  font-family: Tahoma, sans-serif;
  margin-left: 1.5rem;
  text-align: left;
  top: 0;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;

  .heading {
    color: $kt-color-secondary-blue;
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem 1rem 0.5rem;
    cursor: default;
    text-transform: uppercase;
  }

  .dropdown-link {
    color: $kt-heading-color;
    font-size: 1.2rem;
    font-weight: 400;
    cursor: pointer;
    padding: 0.5rem 1rem;

    &:last-child {
      padding: 0.5rem 1rem 1rem;
    }

    &:hover {
      font-weight: 600;
      background-color: $kt-color-off-white;
    }
  }
}

.Kt_dashboard_body_left {
  min-height: 93.7vh;
  background-color: $kt-color-secondary-blue;
  margin-top: 3.3rem;
  width: 5% !important;

  .sidebar {
    position: relative;

    #tooltip {
      left: 4rem;
      top: 0.5rem;
      border-radius: 3px;

      .tooltip-inner {
        padding: 0.5rem;
        color: $kt-heading-color;
        font-size: 1.1rem;
      }
    }

    .sidebar_icon {
      padding: 1.25rem 0;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: $kt-hover-color;
      }

      &_image {
        height: 1.25rem;
        width: 1.25rem;
      }

      &.active {
        background-color: $kt-active-color;
      }
    }
  }
}

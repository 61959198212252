@import 'src/resources/css/theme/KtCommonColors';
.heading_value_left{
    display: flex;
    align-items: center;
}
.marginBottom30{
    margin-bottom: 3rem;
}
.marginTop30{
    margin-top: 3rem;
}
.home_page_main{
    .static-text{
        font-size: 1.2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $kt-color-secondary-blue;
    }
    .main_conatiner{
        background-color: #fff;
        box-shadow: 0 0 0 1px rgba(0,0,0,.1);
        padding:1.5rem;
        border-radius: 5px;
        color: $kt-heading-color;
        &.coming-soon{
            position: relative;
            pointer-events: none;
        }
        .heading{
            font-size: 1.2rem; 
            font-family: 'tahomabd', sans-serif;
            font-weight: 600; 
            color: $kt-heading-color;
            display: flex;
            padding-bottom: 1.5rem;
            &_text{
                flex:1;
                width: 90%;
            }
            &_sortby{
                .kt-drop-down-container{
                    padding-top: 0;
                    border: 1px solid $kt-heading-color;
                    border-radius: 3px;
                    .dropdown{
                        .dropdown-toggle{
                            font-size: 1rem;
                            padding:0.2rem 0.5rem;
                            color:$kt-heading-color;
                            font-weight: 500;
                            opacity: 0.9;
                        }
                        .dropdown-menu.show{
                            padding-top: 0;
                            width: 100%;
                            .dropdown-item{
                                color:$kt-heading-color;
                                opacity: 0.8;
                                font-size: 1rem;
                                padding:.5rem 1rem;
                                font-weight: 500;
                            } 
                        }
                    }
                }
            }
            img{
                height: 1.5rem;
                width: 1.5rem;
                cursor: pointer;
            }
        }
        .chart__table{
            &__header{
                color: $kt-heading-color;
            }
            margin-top:0.5rem;
            .week{
                width: 35%;
            }
            .hours{
                width: 20%;
                text-align: center;
            }
            .status{
                width: 45%;
                text-align: center;
            }
            .downloadTitle{
                width: 70%; 
            }
            .billabilityType{
                flex:1;
                width: 80%;
                font-size: 1.1rem;
            }
            .downloadCount{
                width: 30%;
            }
            .topClient{
                width: 55%;
            }
            .topClienthours{
                width: 15%;
                text-align: center;
            }
            .charges{
                width: 30%;
                text-align: center;
            }
            .data_table_body{
                max-height: 25rem;
                &.timesheet_body{
                    max-height: 20rem;
                }
                overflow: auto;
                &_row{
                    display: flex;
                    align-items: center;
                    height: 2.5rem;
                    font-size: 1.1rem;
                    border-bottom:1px solid $gray-border-color;
                    &.engagement_depth_row{
                        height: 5rem;
                    }
                    &:last-child{
                        border: none;
                    }
                    .week{
                        padding-left: 1rem;
                    }
                    .overTheoCap{
                        color: #734C00;
                        background-color: #FFF2C2;
                        margin-right: 0.5rem;
                        border-radius: 3px;
                    }
                    .underTheoCap{
                        color: #734C00;
                        background-color: #F7CBCF;
                        margin-right: 0.5rem;
                        border-radius: 3px;
                    }
                    .late{
                        color: #6b0000;
                        background-color: #ffbcbc;
                        margin-right: 0.5rem;
                        border-radius: 3px;
                    }
                    .downloadCount,.downloadTitle{
                        padding: 0 1rem;
                        font-size: 1.1rem;
                        font-weight: 400;
                        &.count{
                            margin-left:3rem;
                        }
                    }
                    .billabilityValue{
                        font-size: 1.1rem;
                        padding-right: 1rem;
                    }
                    .downloadTitle,.topClient{
                        cursor: pointer;
                        text-decoration: none;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: $kt-heading-color;
                    }
                    .downloadTitle:hover{
                        color: #113865;
                        text-decoration: underline;
                    }
                    .knTitle:hover{
                        text-decoration: none;
                        cursor: default;
                    }
                    .topClient{
                        padding: 0 1rem;
                        cursor: default;
                    }  
                    .charges,.topClienthours{
                        text-align: center;
                    } 
                }
            }
        }
    }
    &_split_row{
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        &.first_row{
            margin-top:1.5rem;
        }
        &_kt_pulse, &_team_size{
            flex:1;
            height: 40rem;  
            
        }
        &_case_projects{
            height: 35rem;
        }
        &_top_clients,&_ct_satisfaction{
            height: 35rem;
            &.lineManagerTile{
                height: 40rem;
            }
        }
        &_top_downloads{
            height: 35rem;
            margin-right: 2rem;
        }
        
        &_team_size{ 
            margin-left: 2rem;
        }
        &_top_projects,&_kn_material{ 
            height: 35rem;
            margin-right: 2rem;
            &.lineManagerTile{
                margin-right: 0;
            }
        }
        &_engagement_depth{
            flex:1;
            height: 35rem;
            margin-right: 2rem;
        }
        &_billability_trend{
            height: 35rem;
        }
        &_cd_billability{
            height: 22rem;
            margin:0 2rem 2rem;
            .total{
                font-size: 2rem;
                color:$kt-color-secondary-blue;
                font-weight: 600;
                margin: -0.5rem 0 0.5rem;
            }
        }
        &_timesheet_admin{
            height: 11rem;
            margin:0 2rem 0;
        }
        &_kt_billability{
            height: 35rem;
        }
        &_timesheet{
            height: 35rem;
        }
        &_timesheet,&_timesheet_admin{
            .timesheet_total{
                display: flex;
                align-items: center;
                &_val{
                    margin-right: 2rem;
                    .total_values{
                        font-size: 2rem;
                        font-weight: bolder;
                        display: flex;
                        align-items: center;
                        .dollarSign{
                            font-size: 1.1rem;
                            color: $kt-heading-color;
                            margin-left:.5rem;
                            margin-top: .6rem;
                        }
                        .legendsColor{
                            height: 2rem;
                            width: 1rem;
                            margin-right: 1rem;
                        }
                        .onTime{
                            background-color: #B6DDAD;
                            font-size: 2rem;
                        }
                        .Late{
                            background-color: #ffbcbc;
                            font-size: 2rem;
                        }
                    }
                    .legends{
                        font-size: 1.1rem;
                        font-weight: 600;
                        padding-left: 1.9rem;
                    }
                }
            }
        }

        .engagement_depth_bar_chart{
            margin-bottom:2.5rem;
            .legends_bar{
                height: 1rem;
                border-radius: 5px;
                background-color:#d6d6d6;
                position: relative;
                width: 100%;
                &_blue{
                    background-color: $kt-color-secondary-blue;
                    height: 1rem;
                    border-radius: 5px;
                    position: absolute;
                }
            }
            &.displayFlex{
                display: flex;
                margin: 0;
                align-items: center;
                padding-left: 0.5rem;
                .legends_bar{
                    width: 90%;
                }
            }
            .total{
                margin-left: 1rem;
                font-weight: bold;
            }
            .legend_top{
                display: flex;
                font-size: 1.2rem;
                color: $kt-heading-color;
                font-weight: bolder;
                margin-bottom: .5rem;
                .legends{
                    flex:1;
                }
            }
        }

        .highchart_container_loader{
            rotate: 90deg;
            position: absolute;
            margin-top: -15rem;
            margin-left: 20rem;
        }
        .highchart_container_chart{
            height: 80%;
            width: 100%;
            overflow-x: auto;
            margin: -1rem 0 1rem;
        }
        .highchart_container_bottom{
            height: 20%;
            .metric_legends{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                .total_values{
                    font-size: 1.7rem;
                    font-weight: bolder;
                }
                .metric_legend_item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-right: 2rem;
            
                    .legend-icon {
                      width: 1.1rem;
                      height: 1.1rem;
                      content: '';
                      border-radius: 100%;
                      margin-right: 1rem;
                    }
            
                    .heading {
                      font-size: 1.1rem;
                      font-family: tahoma, sans-serif;
                      line-height: 1.4rem;
                      padding-bottom: 0;
                    }
                }
            }
        }
        .pie_chart_wrapper_rms{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 90%;
            position: relative;
            .pie_chart {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                min-height: 80%;
                justify-content: space-between;
              }
        }
    }
}

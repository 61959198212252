@import 'src/resources/css/theme/KtCommonColors';

.admin-level-filers-container {
  padding: 1.8rem 0;
  border-bottom: 0.1rem solid $kt-color-secondary-black;
  display: flex;
  align-items: center;

  .kt-admin-filter-dropdown {
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid $kt-color-primary-blue;
    border-radius: 1rem;
    color: $kt-color-primary-blue;
    font-size: 1.1rem;
    margin-right: 2rem;
    cursor: pointer;

    &.mL05 {
      margin-left: 0.5rem;
      margin-right: 0;
    }

    &.m0 {
      margin: 0;
    }

    &.blueBg {
      background-color: $kt-color-light-blue;
    }

    .select-filter {
      padding: 0.5rem 1rem;

      &.padding05 {
        padding: 0.5rem;
      }

      .kt-admin-arrow {
        height: 1.1rem;
        width: 1rem;
        margin-left: 1rem;

        &.transform-90 {
          transform: rotate(-90deg);
        }

        &.transform90 {
          transform: rotate(90deg);
        }
      }
    }
  }

  .select-type-text {
    color: $kt-color-active-black;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6rem;
    margin-right: 2.4rem;
  }

  .filter-icon {
    margin-right: 1.2rem;
  }

  .separator {
    border: 0.1rem solid $kt-color-secondary-black;
    margin: 0 2rem 0 0.8rem;
  }

  .select-department-container {
    display: flex;
    align-items: center;

    .select-type-text {
      margin-right: 1.8rem;
    }

    .kt-m-checkbox {
      margin-right: 2.4rem;

      .checkbox-label {
        color: $kt-color-primary-darkest-2-blue;
        font-size: 1.1rem;
        line-height: 1.4rem;
      }
    }
  }

  .type-selection-container {
    min-width: 38.5rem;
    display: flex;
    align-items: center;

    .kt-m-checkbox {
      opacity: 0.7;
    }
  }
}

.admin-filter-container {
.error{
  color:rgb(203, 77, 77);
  padding-top: .5rem;
}
  .collection-list{
    margin-bottom: 1rem;
    border-bottom: 1px solid $kt-color-light-gray-2;
    padding-bottom: 1rem;
    .collection-item{
      color: $kt-heading-color;
      margin-bottom: 1rem;
      border: 1px solid $kt-heading-color;
      height: 2.5rem;
      border-radius: 0.5rem;
      background-color: $white;
      padding: 0.5rem 1rem 0.5rem 1rem;
      display: flex;
      align-items: center;
      position: relative;
      &:hover, &.active{
        background-color: $kt-color-off-white;
        cursor: pointer;
        font-weight: 600;
      }
      .collection-name{
        line-height: 2rem;
        flex:1;
      }
      .collection-icon{
        font-size: 1.3rem;
        font-weight: bolder;
        display: flex;
        cursor: pointer;
        margin-top: -.7rem;
      }
    }
    .edit-delete{
      position: absolute;
      background-color: $white;
      height: max-content;
      top:1.5rem;
      right: -1.5rem;
      z-index: 1;
      box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.2);
      .edit,.delete{
        color: $kt-color-secondary-blue;
        font-size: 1.1rem;
        font-weight: 500;
        cursor: pointer;
        padding: 1rem 1rem 1rem;
        &:hover {
          font-weight: 600;
          background-color: $kt-color-off-white;
        }
      }
    }
  }
  &.padding-10{
    padding:1rem;
  }
  // padding: 1.2rem;
  z-index: 3;
  top: 3rem;
  left: 0;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0.2rem 1rem 0 rgb(0 0 0 / 20%);
  color: rgb(12 40 72 / 70%);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  
  cursor: text;
  min-width: stretch;
  .filter-list-search {
    margin-bottom: 1rem;
    border: 1px solid $kt-color-secondary-blue;
    height: 3rem;
    border-radius: 0.5rem;
    background-color: $white;
    &.kt-search-container {
      .kt-search-rectangle-2 {
        align-items: center;
        height: 3rem;

        .kt-small-search-icon {
          margin-top: 0;
        }

        .kt-search-input {
          background: transparent;
          line-height: 2rem;
          width: 100%;
          padding: 0.5rem 1rem 0.5rem 0;
          &.paddingRight{
            padding: 0.5rem 1rem;
          }
        }
      }
    }
  }

  .disabled {
    cursor: default;
    color: $kt-color-inactive-gray;
    pointer-events: none;
  }

  .selected-section {
    margin: 2rem 0;
    font-size: 1rem;
    letter-spacing: 0;
    font-weight: bold;
    color: #22446c;
  }

  .filter-list {
    max-height: 20rem;
    overflow-y: auto;
    padding-right: 1rem;
    &.fullHeight{
      max-height: fit-content;
    }
    &.showMaxHeight{
      max-height: 50rem;
    }

    .filter-display {
      display: flex;
      align-items: center;
      padding: 0.15rem 0;
      width: 100%;
      cursor: pointer;
      &:hover {
        font-weight: 600;
        background-color: $kt-color-off-white;
      }
      img{
        height: 1.8rem;
        margin-left: -2px;
        margin-right: .4rem;
      }

      &.singleSelectFilter{
        margin-bottom: 0;
        padding-top: 0.5rem;
        &:hover,&.highlighted{
          background-color: $kt-color-off-white;
          color: $kt-color-primary-blue;
          font-weight: 600;
        }
      }

      &__radio {
        line-height: 2rem;
        cursor: pointer;
        margin-bottom: 0;
      }

      .filter-name {
        font-size: 1.1rem;
        color: $kt-name-text-color;
        width: max-content;
        &.singleSelect{
          margin-bottom: 0.5rem;
          cursor: pointer;
        }
      }
    }
  }

  .kt-m-checkbox .darkest-blue.unchecked {
    background: $white 0% 0% no-repeat padding-box;
    border: 1px solid $kt-checkbox-color;
  }

  .kt-m-checkbox .darkest-blue.checked {
    background-color: $kt-name-text-color;
  }

  .button-footer {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.5px;
    justify-content: flex-end;

    .apply {
      background: #32a27c 0% 0% no-repeat padding-box;
      border: 1px solid #aeafb1;
      border-radius: 4px;
      padding: 0.3rem 1rem;
      color: $white;
      cursor: pointer;
      width: auto;
      flex: 1;
      text-align: center;

      &.save{
        margin-right: 1rem;
        flex: 1;
        text-align: center;
      }

      &.disabled {
        cursor: default;
        background: #aeafb1 0% 0% no-repeat padding-box;
        pointer-events: none;
      }
    }

    .clear {
      background: #fff 0% 0% no-repeat padding-box;
      border: 1px solid #8c8d8f;
      border-radius: 4px;
      padding: 0.3rem 1rem;
      color: #8c8d8f;
      cursor: pointer;
      width: auto;
      margin-right: 1rem;
    }
  }
  &.filter-container{
    position: inherit;
    background-color: transparent;
    box-shadow: none;
    z-index: inherit;
    // padding: 1rem 0;
    width: 100%;
    max-width: 100%;
    
    .kt-m-checkbox {
      .darkest-blue.unchecked {
        border: 1px solid $kt-color-secondary-blue;
      }
      .darkest-blue.checked {
        background-color: $kt-color-secondary-blue;
      }
    }
    .filter-list .filter-display .filter-name {
      color: $kt-heading-color;
      &.all{
        color: #0774c1;
      }
    }
    .button-footer {
      .apply {
        &.disabled {
          background: transparent;
          background: #BFC7D0;
          color: $white;
          border:  1px solid #BFC7D0;
        }
        background: $kt-color-secondary-blue;
        color: $white;
        border: 1px solid $kt-color-secondary-blue;
        padding: 0.3rem 1rem;
      }
      .clear {
        background: transparent;
        color: $kt-color-clear-blue;
        border: 1px solid $kt-color-clear-blue;
        padding: 0.3rem 1rem;
        flex:1;
        text-align: center;
        background-color: $white;
      }
    }
  }
  &.charges_hours_filters{
    background-color: $white;
    box-shadow: 0 .2rem 1rem 0 #0003;
    color: #0c2848b3;
    cursor: text;
    font-size: 1rem;
    font-weight: 500;
    left: 0;
    line-height: 1.4rem;
    min-width: -webkit-fill-available;
    min-width: -moz-available;
    min-width: stretch;
    position: absolute;
    top: 3rem;
    z-index: 3;
  }
  &.sort-by-filters{
    min-width: 10rem;
    width: 17rem;
    top:3.5rem;
  }
}
